
const Pages = () => import("@/modules/home/views/PagesHtml.vue")
const DoctorCourses = () => import("@/modules/home/views/DoctorProfileCourses.vue")



export default (router) => {
    router.addRoute("Main", { path: "/pagessssss", component: Pages });
    router.addRoute("Main", { path: "/doktor-kurslar", component: DoctorCourses });

};
