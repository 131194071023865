const HomeScreen = () => import("./views/HomeScreen.vue");
const EventDetail = () => import("./views/EventDetail.vue");



export default (router) => {
    router.addRoute("Main", { path: "/events", component: HomeScreen });
    router.addRoute("Main", { path: "/event-detail/:id", component: EventDetail });

};
