import ApiService from '@/services/ApiService';
import UserService from '@/services/UserService';
import { createStore } from 'vuex'


const store = createStore({
    state() {
        return {
            currentLanguage: JSON.parse(window.localStorage.getItem("currentLang")),
            translateList: [],
            home_email: "",
            wishlist: "",
            favoriteEvents: [],
            cart: [],
            choosenCategories: [],
            cartCache: false,
            choosenPartner: null,
            choosenDoctor: null,
            favoriteArticle: [],
            termModal : false,
            termDetail:{},
            pageSettings:{},
            loginPageSettings:{},
            settings:{},
            userTab : "",
            learningPlanTab: "",
            moneyArray :[
                {
                  "id" : 1,
                  "name":"$ - US Dollar",
                  "slug" : "USD"
                },
                {
                  "id" : 2,
                  "name":"€ - Euro",
                  "slug" : "EUR"
                },
                {
                  "id" : 3,
                  "name":"₺ - Turkish Lira",
                  "slug" : "TL"
                }
            ],
            selectMoney : {
            },
            notifList : "",
            currentMoneyIcon: localStorage.getItem('current_money'),
        }
    },
    getters: {

        getUserTab(state){
            return state.userTab
        },

        getSettings(state){
            return state.settings
        },

        getLoginPageSettings(state){
            return state.loginPageSettings
        },

        getCurrentMoneyIcon(state){
            return state.currentMoneyIcon
        },

        getNotifList(state){
            return state.notifList
        },

        getCurrentMoney(state){
            return state.moneyArray
        },
        getSelectCurrentMoney(state){
            return state.selectMoney
        },
        getLearningPlanTab(state){
            return state.learningPlanTab
        },
        getPageSettings(state){
            return state.pageSettings
        },

        getfavoriteArticle(state) {
            return state.favoriteArticle
        },
        getChoosenDoctor(state) {
            return state.choosenDoctor
        },
        getChoosenPartner(state) {
            return state.choosenPartner
        },
        getChoosenCategories(state) {
            return state.choosenCategories;
        },

        getMyCart(state) {
            return state.cart;
        },
        getTermDetail(state){
            return state.termDetail
        },

        getTermModalValue(state) {
            return state.termModal;
        },

        getFavoriteEvents(state) {
            return state.favoriteEvents;
        },
        getWishList(state) {
            return state.wishlist;
        },
        getHomeEmail(state) {
            return state.home_email;
        },
        getLanguageList(state) {
            return state.translateList;
        },
        getCurrentLanguage(state) {
            return state.currentLanguage;
        },
        isAuthenticate() {
            return localStorage.getItem("token") ?? false;
        },
        getAccessToken() {
            return localStorage.getItem("token");
        },
        getRefreshToken() {
            return localStorage.getItem("refreshToken");
        },
      

    },
    mutations: {

        setUserProfileTab(state,payload){
            state.userTab = payload
        },

        setSettings(state,payload){
            state.settings = payload;
        },

        setLoginPageSettings(state,payload){
            state.loginPageSettings = payload;
        },


        setCurrentMoneyIcon(state, payload) {
            state.currentMoneyIcon = payload;
        },

        setNotiftoList(state, payload) {
            state.notifList = payload;
        },
        
        setCurrentMoney(state,payload){
            Object.values(state.moneyArray).forEach((val)=>{
               if(val.id == payload){
                state.selectMoney = val
               }
            })
        },
        

        setLearningPlanTab(state,payload){
            state.learningPlanTab = payload
        },
        setPageSettings(state,payload){
            state.pageSettings = payload
        },
       
        addNewFavoriteArticle(state, payload) {
            state.favoriteArticle.push(
                payload,
            );
        },
        removeFavoriteArticle(state, favorite) {
            state.favoriteArticle.forEach((element, index) => {
                if (element.id == favorite.id) {
                    state.favoriteArticle.splice(index, 1)
                }
            });
        },
        removeActiveTab(state) {
            state.learningPlanTab = ""
        },


        setTermDetail(state, payload) {
            state.termDetail = payload;
        },
        setChoosenDoctor(state, payload) {
            state.choosenDoctor = payload;
        },
        setChoosenPartner(state, payload) {
            state.choosenPartner = payload;
        },
        fillMyCart(state, payload) {
            state.cart = payload;
        },
        addToMyCart(state, cart) {
            state.cart.push(cart);
        },
        removeFromCart(state, cart) {
            state.cart.forEach((element, index) => {
                if (cart.id == element.id) {
                    state.cart.splice(index, 1);
                }
            })
        },
        removeFavorite(state, favorite) {
            state.favoriteEvents.forEach((element, index) => {
                if (element.id == favorite.id) {
                    state.favoriteEvents.splice(index, 1)
                }
            });
        },
        addNewFavorite(state, payload) {
            state.favoriteEvents.push(

                payload.data,

            );
        },
        setChoosenCategories(state, payload) {
            state.choosenCategories = payload;
        },
        setWishList(state, payload) {
            state.wishlist = payload;
        },
        setHomeEmail(state, payload) {
            state.home_email = payload;
        },
        setCurrentLanguage(state, payload) {
            state.currentLanguage = payload;
        },
        setTranslateList(state, payload) {
            state.translateList = payload;
        },
        setTermModal(state, payload) {
            state.termModal = payload;
        },
        setAuth(state, data) {
            ApiService.setToken(data.token);
            localStorage.setItem("token", data.token);
            UserService.setUser(data.user);
        },
        fillFavoriteEvents(state, payload) {
            state.favoriteEvents = payload;
        },
        fillArticles(state, payload) {
            state.favoriteArticle = payload;
        }
    },
    actions: {
        doLoadMyCart(context) {
            
                const user = UserService.getUser()

                if (!user) {
                    return;
                }

               
                ApiService.post('cart/getCartDetail', { user_id: user.id , currency: context.state.currentMoneyIcon , locale:context.state.currentLanguage}).then((response) => {
                    if (response.data) {
                        context.commit('fillMyCart', response.data.cart_detail)
                        context.state.cartCache = true;
                    }
                });
            
        },
        doLoadFavoriteEvents(context) {
            const user = UserService.getUser()

            if (!user) {
                return;
            }
            ApiService.post('favorite/getUserFavoriteEvents', { user_id: user.id }).then((response) => {
                if(response.data != null){
                context.commit('fillFavoriteEvents', response.data.events)
                }

            });
        },


        doLoadArticles(context) {
            const user = UserService.getUser()

            if (!user) {
                return;
            }
            ApiService.post('favorite/getUserFavoriteArticle', { user_id: user.id }).then((response) => {
                if(response.data != null){
                    context.commit('fillArticles', response.data.events)
                }

            });
        }
    }

})

export default store;