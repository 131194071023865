import { createApp } from 'vue'
import App from './App.vue'
import store from '@/store';
import ApiService from './services/ApiService';
import router from '@/router';
import '@/input.css';
import 'swiper/css';
import { registerModules } from "./register-modules.js";
import * as Yup from "yup";

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import ContentFlow from 'incore-element';

import RenderExam from 'incore-quiz-export';

import WaveSurferVue from "wavesurfer.js-vue";
const app = createApp(App)
import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import VueSocialSharing from 'vue-social-sharing';
import moment from 'moment'
app.use(VueSocialSharing);
app.use(VueVideoPlayer);
app.use(ContentFlow);
app.use(RenderExam);
app.use(moment);
/************ START OF REGISTER MODULE ************/
import HomeModule from "@/modules/home";
import CourseDetail from "@/modules/course-detail";
import HomePage from "@/modules/home-page";
import ContactUs from "@/modules/contact-us";
import AboutUs from "@/modules/about-us";
import AreaOfInterest from "@/modules/area-of-interest";
import EventsPage from '@/modules/events-page';
import PartnersPage from '@/modules/partners-page';
import DoctorProfile from '@/modules/doctor-profile';
import ArticleDetail from '@/modules/article-detail';
import UserProfile from '@/modules/user-profile';
import ResourcesPage from '@/modules/resources-page';
import DoctorsClinics from '@/modules/doctors-clinics';
import LoginHome from '@/modules/login-home';
import AllCourses from '@/modules/all-courses';
import CourseDetailModules from '@/modules/course-detail-modules';
import SocialSearch from '@/modules/social-search';
import PaymentMethod from '@/modules/payment-1';
import PodcasdDetail from '@/modules/podcast-detail';
import SignIn from '@/modules/sign-in';
import Search from '@/modules/search-page';
import Cart from '@/modules/cart-model';
import Ticked from '@/modules/TickedPage';
import Request from '@/modules/Request-form';
import Faq from '@/modules/Faq-module';
import FixedPages from '@/modules/Fixed-Pages';
import ErrorPAge from '@/modules/404ErrorPage';
import ClickOutside from '@/directives/clickOutside';
import Terms from '@/modules/Terms-module'







registerModules({
    home: HomeModule,
    courseDetail: CourseDetail,
    HomePage: HomePage,
    ContactUs: ContactUs,
    AboutUs: AboutUs,
    AreaOfInterest: AreaOfInterest,
    EventsPage: EventsPage,
    PartnersPage: PartnersPage,
    DoctorProfile: DoctorProfile,
    ArticleDetail: ArticleDetail,
    UserProfile: UserProfile,
    ResourcesPage: ResourcesPage,
    DoctorsClinics: DoctorsClinics,
    LoginHome: LoginHome,
    AllCourses: AllCourses,
    CourseDetailModules: CourseDetailModules,
    SocialSearch: SocialSearch,
    PaymentMethod: PaymentMethod,
    PodcasdDetail: PodcasdDetail,
    SignIn: SignIn,
    Search: Search,
    Cart: Cart,
    Ticked:Ticked,
    Request:Request,
    Faq:Faq,
    FixedPages:FixedPages,
    ErrorPAge:ErrorPAge,
    Terms : Terms
})

app.directive('click-outside',ClickOutside);
/************ END OF REGISTER MODULE ************/
app.use(WaveSurferVue)
app.use(store);
app.use(router);
app.use(ElementPlus)
app.use(Yup)



ApiService.init(app, store);


//
const language = localStorage.getItem('currentLang');

if(language){
    store.commit('setCurrentLanguage',JSON.parse(language));
}
else {
    ApiService.post('langs/langList',{}).then((response)=> {
    store.commit("setCurrentLanguage",response.data.langs[0])
    localStorage.setItem('currentLang',JSON.stringify(response.data.langs[0]))
    })
}


app.mount('#app');