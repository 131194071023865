const HomeScreen = () => import("./views/HomeScreen.vue");
const Register = () => import("./views/RegisterSection.vue");



export default (router) => {
    router.addRoute("Main", { path: "/sign-in", component: HomeScreen });
    router.addRoute("Main", { path: "/register", component: Register ,  props: {
        email: true,
     },});

};