<template>

<Teleport to='body' >
  <div class="webLoader fixed w-full h-full bg-white flex items-center justify-center z-[1000000] top-0">
    <Vue3Lottie :animationData="LoadingAnimation" :height="400" :width="400" />
    
  </div>
</Teleport>
<el-dialog v-if="termDetail != false" v-model="showTermModal" width="30%" title="" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
  <div v-if="termDetail.translate">
    <div class="flex justify-center">
      <h1 class="text-[23px] font-bold text-[#000759]" >OUR USER AGREEMENT IS RENEWED</h1>
    </div>
    <div class="mt-3">
      <h1  class="text-[20px] font-semibold text-[#000759]" v-html="termDetail.translate[currentLanguage.id].termTitle"></h1>
    </div>
    <div class="mt-3 mb-3">
      <p  class="h-[400px] px-2 overflow-y-scroll" v-html="termDetail.translate[currentLanguage.id].termContent "></p>
    </div>
    <form @submit="submitterm(termDetail.id,termDetail.version,$event)" >
      <div class="mt-3">
        <div  class="flex items-center gap-2 mt-4 ">
          <label class="customCheckboxArea  flex justify-center" >
            <input  required type="checkbox" name="checkbox" />
          </label>
          <span class="ml-2 text-[#000759] font-semibold ">I have read and I accept the <strong  class=" cursor-pointer">Terms and Conditions</strong></span>
        </div>
        <div class="mt-2 flex w-full justify-end pr-2">
          <button type="submit" class="px-6 py-1 rounded-[3px] bg-[#000759] text-white">Accept</button>
        </div>
      </div>
    </form>
  </div>
</el-dialog>
    <el-backtop :right="30" :bottom="30">
      <div
        style="
          height: 100%;
          width: 100%;
          background-color: var(--el-bg-color-overlay);
          box-shadow: var(--el-box-shadow-lighter);
          text-align: center;
          border-radius: 100% !important;
          line-height: 40px;
          color: white;
          background:#000759
        "
      >
      <i class="bi bi-arrow-up"></i>
      </div>
    </el-backtop>
  <router-view />
</template>

<script>
import { Vue3Lottie } from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'

import LoadingAnimation from '@/assets/animation/loading.json'
import ApiService from './services/ApiService';
import { computed, onMounted , onBeforeMount } from 'vue';
import store from '@/store'
import UserService from './services/UserService';
export default {
  name: 'App',
  components: {
      Vue3Lottie
    },
  setup() {




  onBeforeMount(()=>{
    ApiService.post('settings/getSettings',{}).then((response)=> {
      store.commit("setSettings",response.data.settings)
    })
  })
   



    
    onMounted(() => {
      ApiService.get("langs/translateList").then((response) => {
        store.commit('setTranslateList', response.data.list)
      }).catch(() => {
      });
    });

    const submitterm = (termId,version,e) => {
      e.preventDefault();
      
      const user = UserService.getUser();

      if(!user)return;
      var formData = new FormData();

      formData.append('user_id',user.id);
      formData.append('term_id',termId);
      formData.append('version',version);
        ApiService.post("terms/userTerm",formData).then((response) => {
          console.log(response);
          store.commit('setTermDetail',false)
          if(response.success == "true"){
            showTermModal.value = false
          }
          else{
            showTermModal.value = false
          }
        }).catch(() => {
        });
      }
     const showTermModal = computed(()=>{
        return store.getters.getTermModalValue
      })

      const termDetail = computed(()=>{
        return store.getters.getTermDetail
      })

      const currentLanguage = computed(() => {
      return store.getters.getCurrentLanguage;
    });
  
    return{
      showTermModal,
      termDetail,
      submitterm,
      LoadingAnimation,
      currentLanguage
    }
  }
}
</script>

<style lang="css">
@import '@/assets/css/mystyle.css';

@font-face {
  font-family: elyazisi;
  src: url(@/assets/fonts/Crunchy_PERSONAL_USE_ONLY.ttf);
}

@font-face {
  font-family: homefont;
  src: url(@/assets/fonts/DharmaGothicE_Bold_R.otf);
}


blockquote {
  overflow: hidden;
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin-left: 0;
  margin-right: 0;
  font-style: italic;
  border-left: solid 5px hsl(0,0%,80%);
}

.demo-tabs>.el-tabs__content {
  box-shadow: inset;
}

.image {
display: flex;
align-items: center;
justify-content: center;
}
.image.image-style-block-align-left {

  justify-content: start;
}
.image.image-style-block-align-right {

  justify-content: end;

}
</style>
