
class UserService {
    static getUser = () => {
      return JSON.parse(localStorage.getItem('user'));
    }
    static setUser = (user) => {
      return localStorage.setItem('user',JSON.stringify(user));
    }
    static deleteUser = () => {
      return localStorage.clear();
    }
}

export default UserService;