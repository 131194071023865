const HomeScreen = () => import("./views/HomeScreen.vue");
const PartnerProfileDetail = () => import("./views/PartnersProfileDetail");



export default (router) => {
    router.addRoute("Main", { path: "/partners", component: HomeScreen });
    router.addRoute("Main", { path: "/partner-profile-detail/:slug/:id", component: PartnerProfileDetail, });

};
