import axios from "axios";
import vueAxios from "vue-axios";

class ApiService {
    static init(app, store) {
        app.use(vueAxios, axios);
        axios.defaults.headers.common["Accept"] = "application/json";
        axios.defaults.headers.common["Content-Type"] = "application/json; charset=UTF-8";
        if (store.getters.getAccessToken) {
            axios.defaults.headers.common["Authorization"] = "Bearer " + store.getters.getAccessToken;
        }
        axios.defaults.baseURL = process.env.VUE_APP_API_URL
    }

    static setToken(token) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    static get = (url, payload) => new Promise(function (resolve, reject) {
        axios.get(url, { params: payload }).then(({ data }) => {
            resolve(data);
        }).catch((err) => {
            reject(err);
        });
    })

    static del = (url, payload) => new Promise(function (resolve, reject) {
        axios.delete(url, { params: payload }).then(({ data }) => {
            resolve(data);
        }).catch((err) => {
            reject(err);
        });
    })

    static post = (url, payload) => new Promise(function (resolve, reject) {

        axios.post(url, payload).then(({ data }) => {
            resolve(data);
        }).catch((err) => {
            reject(err.response.data);
        });

    })

    static put = (url, payload) => new Promise(function (resolve, reject) {

        axios.put(url, payload).then(({ data }) => {
            resolve(data);
        }).catch((err) => {
            reject(err.response.data);
        });

    })
}

export default ApiService;