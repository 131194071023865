
import ApiService from "@/services/ApiService";
import UserService from "@/services/UserService";
import store from "@/store";
import { createRouter, createWebHistory } from "vue-router";
const App = () => import("@/App.vue");

const routes = [
  { name: "HomePage", path: '/', component: App, redirect: '/home', meta: { title: 'Skills - MyApp' } }, 
  {  path: '/:pathMatch(.*)*', redirect:"/home" },


];


const router = createRouter({
  history: createWebHistory(),
  routes: routes,
})

const user = UserService.getUser()
router.beforeEach((to, from, next) => {

  


  store.commit('setTermModal',false);
  if(user){
    ApiService.post('terms/checkTerm',{
      user_id : user.id,
    }).then((response) => {
      if(response.success == "false"){

        console.log("SAAAA")
        store.commit('setTermModal',true);
        store.commit('setTermDetail',response.data.term_detail);
      }
    })
  }



  document.querySelector('.webLoader').style.display = 'flex';

  setTimeout(() => {
    
    document.querySelector('.webLoader').style.display = 'none';
    },1000);

  store.dispatch('doLoadArticles');

  store.dispatch('doLoadMyCart');
  store.dispatch('doLoadFavoriteEvents');
  let token = store.getters.isAuthenticate


  if (token) {
    ApiService.post('auth/verify').then((response) => {
      if (response.success) {
        store.commit('setAuth', response.data);
        next();
      }
    }).catch((err)=> {
      next("/home")
      console.log(err);
      UserService.deleteUser()
    })
  }



  let allowedPaths = [
    '/home',
    '/sign-in',
    '/area-of-interest',
    '/area-of-interest-result',
    '/event-detail',
    '/doctor-profile',
    '/resources',
    '/partners',
    '/partner-profile-detail',
    '/doctors-clinics',
    '/social-search',
    '/events',
    '/all-courses',
    '/DoctorProfile',
    '/course',
    '/course-detail-modules',
    '/ticket',
    '/contact-us',
    '/faq',
    '/register',
    '/request-form',
    '/course-content',
    '/pages',
    '/404',
    '/course-detail-module-content',
    '/terms'

  ];
  if (allowedPaths.includes('/' + to.fullPath.split('/')[1])) {
    next();
    return
  }


});


router.afterEach(() => {
  window.scrollTo({ top: 0 });
})

export default router;

